html,
body,
#tplt_all,
#tplt_body,
#myApp,
#root {
  margin: 0px;
  padding: 0px;
  font-family: "Hiragino Kaku Gothic ProN";
}

div.user-icon {
  border-radius: 20px;
  width: 24px;
  height: 24px;
  display: inline-block;
}

div.choice-container {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  align-content: space-around;
  margin-left: 10px;
}