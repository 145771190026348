.BlAnAggregateJobEditor__import-type-block {
    display: none;
}

#targets-table-add-axis {
    display: none
}

.BleuValidatableInput__annotation.BleuValidatableInput__annotation--visible::before {
    display: none;
}

.BlAnAggregateJobEditor > .BleuTitledBlock:first-child {
    display: none;
}

#table-head-0 {
    display: none;
}

.BlAnViewerHeader {
    padding: 1rem 0rem 1rem 0rem !important;
}

.BlAnViewerTable {
    padding: 0em 0rem 2rem !important;
}